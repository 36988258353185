<template>
  <div>
    <b-card>
      <!-- Utils -->
      <b-row class="mb-2">
        <b-col cols="3">
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            :config="{ mode: 'range' }"
          />
        </b-col>
        <b-col>
          <b-button variant="primary"> Cerca </b-button>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-table :items="mappedBookings" :fields="fields" striped responsive>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            @click="onButtonClicked(data.item.localizator)"
          >
            Facturar
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BTable, BButton, BRow, BCol, BCard } from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BButton,
    flatPickr,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rangeDate: null,
      fields: [
        { key: "localizator", label: "Localitzador" },
        { key: "accommodation", label: "Allotjament" },
        { key: "checkin", label: "Entrada" },
        { key: "checkout", label: "Sortida" },
        { key: "actions", label: "Facturació" },
      ],
    };
  },
  computed: {
    bookings() {
      return this.$store.getters["bookings/bookings"];
    },
    mappedBookings() {
      if (!this.bookings.length) return [];
      return this.bookings.map((booking) => ({
        localizator: booking.localizator,
        accommodation: booking.accommodation.name,
        checkin: formatDateStringToDate(booking.checkin),
        checkout: formatDateStringToDate(booking.checkout),
        uuid: booking.uuid,
      }));
    },
  },
  created() {
    this.fetchBookings();
  },
  methods: {
    onButtonClicked(localizator) {
      this.$router.push({
        name: "foravila-accounting-billing-details",
        params: {
          localizator,
        },
      });
    },
    fetchBookings() {
      this.$store.dispatch("bookings/fetchFilteredBookings");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
